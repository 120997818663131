<script>

import axios from "axios"
// import { formatNumber } from "./utils.js"
import { lang } from "./store.js"
// import { onMount } from "svelte";

let email = ""
let errEmail = false
let successResult = false
let errResult = false
let loading = false

function checkEmail (value) {
    return (value && !!value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) || false
}

function send(){
    errEmail = false
    successResult = false
    errResult = false
    if(email == "" || !checkEmail(email)){
        errEmail = true
        return
    }

    let data = {
        email: email
    }
    loading = true
    axios.post('/api/register-email',data, {withCredentials: true})
    .then(function (response) {
        if(response.data.success === true){
            successResult = true
        }
        else{
            errResult = true
        }

    })
    .catch(function (error) {
        // console.log(error);
        errResult = true
    });
    loading = false
}
</script>


<h2 class="title has-text-centered mb-7">
    {($lang == "cs" ? 'Dostávejte novinky e-mailem' : 'Get news to your e-mail')}
</h2>
<div class="subtitle is-size-6">
    {($lang == "cs" ? 'Jednou měsíčně posíláme e-mail s novými produkty. Pokud ho chcete dostávat, zaregistrujte si svůj e-mail zde' : 'Once a month we send email with our new products. If you want to receive it, please register your email address')}
</div>
<div style="max-width:300px" class="mx-auto">
    <div class="control">
        <input class="input" type="email" bind:value={email} placeholder="{($lang == "cs" ? 'Vaše e-mailová adresa' : 'Your email address')}">
    </div>
    {#if errEmail}
    <p class="help is-danger">{($lang == "cs" ? 'Prosím vyplňte e-mailovou adresu' : 'Please fill e-mail address')}</p>
    {/if}
    {#if !successResult}
    <button class="button is-belle-full mt-5 {(loading ? 'is-loading' : '')}" on:click={send}>
        {($lang == "cs" ? 'Chci dostávat e-maily' : 'I want to receive emails')}
    </button>
    {/if}
    {#if successResult}
    <p class="help si-size-6">{($lang == "cs" ? 'Váš e-mail jsme zaregistrovali' : 'We registered your e-mail')}</p>
    {/if}
    {#if errResult}
    <p class="help is-danger">{($lang == "cs" ? 'Někde se stala chyba' : 'There was some error')}</p>
    {/if}
</div>